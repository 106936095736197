import React, { useEffect, useRef } from 'react';

const SnakeCanvas = () => {
	const canvasRef = useRef(null);
	const linesRef = useRef([]);

  	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		let w = (canvas.width = window.innerWidth);
		let h = (canvas.height = window.innerHeight);
		const lineCount = 50;
		let snake_bg_color = document.body.classList.contains('body-style-dark') ? canvas.dataset.coloroned || "#202020" : canvas.dataset.colorone || "#202020";
		const snake_line_color = canvas.dataset.colortwo || "#1e90ff"; // Color por defecto

    	const hexToRgb = (hex) => {
      		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      		return result ? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
          	}
        	: { r: 0, g: 0, b: 0 };
    	};

		function Line() {
			this.location = {
				x: Math.random() * w,
				y: Math.random() * h,
			};
			this.width = Math.random() * 1 + 0.25;
			this.color = snake_line_color;
		}

		const init = () => {
			for (let i = 0; i < lineCount; i++) {
				linesRef.current.push(new Line());
			}
			stage();
			loop();
		};

	    const stage = () => {
			w = canvas.width = window.innerWidth;
			h = canvas.height = window.innerHeight;
			ctx.fillStyle = snake_bg_color;
			ctx.fillRect(0, 0, w, h);
    	};

    	const draw = () => {
      		snake_bg_color = document.body.classList.contains('body-style-dark') ? canvas.dataset.coloroned || "#202020" : canvas.dataset.colorone || "#202020";

      		const rgbColor = hexToRgb(snake_bg_color);

			ctx.fillStyle = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.05)`;
			ctx.fillRect(0, 0, w, h);

			linesRef.current.forEach((line) => {
				const a = Math.floor(Math.random() * 8) * 90;
				const lineLength = Math.random() * 15 + 5;

				ctx.lineWidth = line.width;
				ctx.strokeStyle = line.color;
				ctx.beginPath();
				ctx.moveTo(line.location.x, line.location.y);

				switch (a) {
				case 0:
					line.location.y -= lineLength;
					break;
				case 90:
					line.location.x += lineLength;
					break;
				case 180:
					line.location.y += lineLength;
					break;
				case 270:
					line.location.x -= lineLength;
					break;
				default:
					break;
				}

        		ctx.lineTo(line.location.x, line.location.y);

				if (line.location.x < 0 || line.location.x > w)
					line.location.x = Math.random() * w;
				if (line.location.y < 0 || line.location.y > h)
					line.location.y = Math.random() * h;
        
				ctx.stroke();
      		});
    	};

		const loop = () => {
			draw();
			requestAnimationFrame(loop);
		};

		window.addEventListener('resize', stage);
		init();

		return () => {
			window.removeEventListener('resize', stage);
		};
  }, []);

  return (
	<div id="canvas_snake" data-colorone="#202020" data-coloroned="#202020" data-colortwo="#1e90ff">
		<canvas ref={canvasRef} style={{ display: 'block' }} />
	</div>
  );
};

export default SnakeCanvas;
