import React from 'react';
import './App.css';
import Preloader from './components/Preloader/Preloader';
import SnakeCanvas from './components/Canvas/Snake';

function App() {
  return (
    <div className="App">
      <Preloader />
      <SnakeCanvas />
    </div>
  );
}

export default App;
