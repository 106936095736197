import React, { useEffect, useState } from 'react';
import './Preloader.scss';

const Preloader = () => {
    const [spinnerVisible, setSpinnerVisible] = useState(true);
    const [preloaderVisible, setPreloaderVisible] = useState(true);

    useEffect(() => {
        const spinnerTimeout = setTimeout(() => {
            setSpinnerVisible(false);
        }, 1000);

        const preloaderTimeout = setTimeout(() => {
            setPreloaderVisible(false);
        }, 2000);

        return () => {
            clearTimeout(spinnerTimeout);
            clearTimeout(preloaderTimeout);
        };
    }, []);

    if (!preloaderVisible) return null;

    return (
        <div className={`preloader ${!preloaderVisible ? 'preloader--hidden' : ''}`}>
            <div className="centrize full-width">
                <div className="vertical-center">
                    <div className={`spinner quantum-spinner ${!spinnerVisible ? 'spinner--hidden' : ''}`}></div>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
